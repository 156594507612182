import React from 'react';
import Header from '../components/Header102/Header';
import Footer from '../components/Footer0/Footer';

const Layout = ({ children }) => {
  return (
    <div>
      <Header
        title="社労士事務所jest"
        logoImage="/jets/jets-logo.png"
        navItems={[
          {
            id: 1,
            name: '事業案内',
            link: '/about',
            dropdown: false,
          },
          {
            id: 2,
            name: '代表挨拶',
            link: '/founder',
            dropdown: false,
          },
          {
            id: 4,
            name: 'お問い合わせ',
            link: '/contact',
            dropdown: false,
          },
        ]}
      />
      {children}
      <Footer copyrightText="社労士事務所JETS" />
    </div>
  );
};

export default Layout;
