import React from 'react';
import * as styles from './Footer.module.css';

interface FooterProps {
  copyrightText: string;
}

const Footer: React.FC<FooterProps> = ({ copyrightText }) => {
  const currentYear = new Date().getFullYear();
  return (
    <footer className={styles.footer}>
      <p>
        <small>
          {currentYear} &copy; {copyrightText} All Right Reserved.
        </small>
      </p>
    </footer>
  );
};

export default Footer;
