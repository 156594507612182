import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

const Seo = ({ description, title, children, cover, noindex = false }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
          }
        }
      }
    `,
  );

  const siteUrl = site.siteMetadata.siteUrl;
  const metaDescription = description || site.siteMetadata.description;
  const defaultTitle = site.siteMetadata.title;
  const ogpCover = cover || `${siteUrl}/corporate_ogp.png`;

  return (
    <>
      <title>{title ? `${title} | ${defaultTitle}` : defaultTitle}</title>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0"
      ></meta>
      <meta name="description" content={metaDescription} />
      {noindex && <meta name="robots" content="noindex" />}
      <meta property="og:site_name" content={defaultTitle} />
      <meta property="og:image" content={`${ogpCover}`} />
      <meta
        property="og:title"
        content={title ? `${title} | ${defaultTitle}` : defaultTitle}
      />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:description" content={metaDescription} />
      <meta
        name="twitter:title"
        content={title ? `${title} | ${defaultTitle}` : defaultTitle}
      />
      <meta name="twitter:description" content={metaDescription} />
      {children}
    </>
  );
};

export default Seo;
